import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { gsap } from "gsap";
import "../../style.css";
//import video from "../../videoPaths.js"
import { useLanguage } from '../../Contexts/LanguageContext.jsx';
import MenuLang from "../Pages/MenuLang.jsx";

export default function IntroBackground({ show, audio, soundOn, windowRatio }) {

	const navigate = useNavigate();
	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	const [playing, setPlaying] = useState(false);

	//Set up audio
	useEffect(() => {
		audio.currentTime = 0;
		audio.addEventListener('ended', () => setPlaying(false));
		return () => {
			audio.removeEventListener('ended', () => setPlaying(false));
		};
	}, []);

	// Toggle the audio playing according to sound setting
	useEffect(() => {
		if (soundOn) {
			setPlaying(true);
			gsap.to(audio, { volume: 0.8, ease: "none", duration: 1 });
		}
		if (!show || !soundOn) {
			setPlaying(false);
			gsap.to(audio, { volume: 0, ease: "none", duration: 0.3 });
		}
	}, [soundOn, show]);

	useEffect(() => {
		playing ? audio.play() : audio.pause();
		if (audio && playing) audio.volume = 0;
	}, [playing]);

	return <>
		<section id="into-background">
			<button
				className={show ? "show icon introLogo" : "hide icon introLogo"}
				onClick={() => navigate("/")}
			>
				<img src="/images/general/logo.png" />
			</button>

			<h4 className={show ? "show" : "hide"}>
				{currentLanguage == 'fr' ? 'UNE EXPERIENCE AUDIOVISUELLE ET INTERACTIVE' : 'AN AUDIOVISUAL AND INTERACTIVE EXPERIENCE'}
			</h4>

			<MenuLang />
		</section>

		<div
			id="video-wrapper"
			className={show ? "show" : "hide"}
			style={windowRatio > 16 / 9 ?
				{ width: "100vw", height: `${100 * 9 / 16}vw`, top: `${(-(9 / 16) * window.innerWidth + window.innerHeight) / 2}px`, zIndex: 9 } :
				{ height: "100vh", width: `${100 * 16 / 9}vh`, left: `${(-(16 / 9) * window.innerHeight + window.innerWidth) / 2}px`, zIndex: 9 }
			}
		>
			<ReactPlayer
				id="intro-video"
				url={video.intro}
				controls={false}
				height='100%'
				width='100%'
				playing={true}
				muted={true}
				loop={true}
				onStart={() => document.querySelector("#videoblackFadeIn").classList.add("videofadeIn")}
				fileconfig={{ attributes: { playsinline: true } }}
				playsinline={true}
			/>
		</div>

		<div id="videoblackFadeIn" />
	</>
}
