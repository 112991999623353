import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import subtitleFR from "../../videoSubtitles-fr.js"
import subtitleEN from "../../videoSubtitles-en.js"
import { useLanguage } from '../../Contexts/LanguageContext.jsx';
import ReactPlayer from "react-player";
import TouchPoint from "./TouchPoint.jsx";
import Menu from "./Menu.jsx";
import Timeline from "./Timeline.jsx";
import VideoButtons from "./VideoButtons.jsx";
import "../../style.css";
import "./visitStyle.css"

let timeout
export default function Laboratoires({ windowRatio, soundOn, onSoundClick, path, videoParts, videoPath }) {

	const player = useRef();
	const navigate = useNavigate();
	const { currentLanguage, setCurrentLanguage } = useLanguage();

	const [showMenu, setShowMenu] = useState(false);
	const [closeMenu, setCloseMenu] = useState(false);
	const [newX, setNewX] = useState();
	const [showVideo, setShowVideo] = useState(false);
	const [touchPointsettings, setTouchPointSettings] = useState({ show: false, time: 0, x: 0, y: 0 });
	const [currentVideoPartIndex, setCurrentVideoPartIndex] = useState(0);
	const [enableTouchPoints, setEnableTouchPoints] = useState(false);
	const [duration, setDuration] = useState(0);
	const [startTimeline, setStartTimeline] = useState(false);
	const [pause, setPause] = useState(true);
	const [buffering, setBuffering] = useState(false);
	const [playerReady, setPlayerReady] = useState(false);

	const [playerConfig, setPlayerConfig] = useState({
		file: {
			attributes: {
				crossOrigin: 'anonymous',
			},
		},
	});

	useEffect(() => {
		let videoName = '';

		if (location.pathname == '/laboratoires') {
			videoName = 'laboratoires';
		} else if (location.pathname == '/usine') {
			videoName = 'usine';
		}

		if (currentLanguage === 'fr') {
			if (
				subtitleFR &&
				(subtitleFR[videoName] !== undefined && subtitleFR[videoName] !== null && subtitleFR[videoName] !== "")
			) {
				setPlayerConfig((prevConfig) => ({
					...prevConfig,
					tracks: [
						{
							kind: 'subtitles',
							src: subtitleFR[videoName],
							srcLang: currentLanguage,
							default: true,
						},
					],
				}));
			}
		} else {
			if (
				subtitleEN &&
				(subtitleEN[videoName] !== undefined && subtitleEN[videoName] !== null && subtitleEN[videoName] !== "")
			) {
				setPlayerConfig((prevConfig) => ({
					...prevConfig,
					tracks: [
						{
							kind: 'subtitles',
							src: subtitleEN[videoName],
							srcLang: currentLanguage,
							default: true,
						},
					],
				}));
			}
		}
	}, [currentLanguage]);

	const [state, setState] = useState({
		url: null,
		pip: false,
		playing: false,
		controls: false,
		light: false,
		volume: 0.8,
		muted: !soundOn,
		played: 0,
		loaded: 0,
		duration: 0,
		playbackRate: 1.0,
		loop: false,
		seeking: false
	});

	// If pause is triggered by videoButtons.jsx, pause the player
	useEffect(() => {
		if (!touchPointsettings.show && showVideo) setState({ ...state, playing: !pause });
		else setPause(!state.playing);
	}, [pause]);

	// Checks for the pathname
	// If we are on the right one (/laboratoires for the laboratoires visit, or /usine for the usine visit),
	// set up the payer to start, the timeline to run, enable touchpoints, and fadeIn from the back screen.
	// Else, stop the player, hide the timeline, disable touchpoints. If we are at the homepage of the end-menu page, reset the player and timeline
	useEffect(() => {
		if (location.pathname == path) {
			setState({ ...state, playing: true });
			setShowVideo(true);
			setStartTimeline(true);
			timeout = setTimeout(() => setEnableTouchPoints(true), 5000);
			if (playerReady) {
				document.querySelector("#videoVisitblackFadeIn").classList.remove("showQuick");

				document.querySelector("#videoVisitblackFadeIn").classList.add("videofadeIn");
				document.querySelector("#videoVisitblackFadeIn").style.zIndex = 2;
			}
		} else {
			setState({ ...state, playing: false });
			setShowVideo(false);
			setShowMenu(false);
			clearTimeout(timeout);
			setEnableTouchPoints(false);
			setTouchPointSettings({ ...touchPointsettings, show: false });
			if (location.pathname == "/" || location.pathname == path + "-end-menu") {
				player.current.seekTo(0, 'fraction');
				setState({ ...state, playing: false, playedSeconds: 0, played: 0 });
				setStartTimeline(false);
			}
		}
	}, [location.pathname]);

	// If the menu is shown, pause the video
	useEffect(() => {
		if (showMenu) setState({ ...state, playing: false });
		else if (location.pathname === path) setState({ ...state, playing: true });
	}, [showMenu]);

	// Change the sound setting on the whole website
	useEffect(() => {
		onSoundClick(!state.muted);
	}, [state.muted]);

	useEffect(() => {
		setState({ ...state, muted: !soundOn });
	}, [soundOn]);

	// If the user seeks to an other timecode on the timeline, hide the current showing touch-point if there is one and update the player accordingly
	const handleSeek = (seekPos) => {
		setState({ ...state, seeking: true });

		setTouchPointSettings({ ...touchPointsettings, show: false });
		player.current.seekTo(seekPos, 'fraction');
		setState({ ...state, playedSeconds: seekPos * duration, played: seekPos, playing: true, });
		setState({ ...state, seeking: false });
	};

	// This function is called by the onProgress() method of the React-player
	// Each time (approximately every seconds), updates the state of the player, updates the currentVideoPartIndex for the menu, and checks if a touch-point should be displayed
	// If we are near the end, triggers the end animation
	const handleProgress = ({ playedSeconds, played, loadedSeconds, loaded }) => {
		if (location.pathname !== path) return;
		if (!state.seeking) {
			setState({ ...state, playedSeconds: playedSeconds, played: played, loadedSeconds: loadedSeconds, loaded: loaded });

			var videoPartPlaying = videoParts[currentVideoPartIndex];
			if ((videoPartPlaying.startInSec > state.playedSeconds) || (state.playedSeconds >= videoPartPlaying.startInSec + videoPartPlaying.lengthInSec)) {
				videoParts.forEach(part => {
					if ((part.startInSec <= state.playedSeconds) && (state.playedSeconds < part.startInSec + part.lengthInSec)) {
						setCurrentVideoPartIndex(videoParts.indexOf(part));
					};
				});
			};

			if (enableTouchPoints && state.playing) {
				videoParts.forEach(videoPart => {
					videoPart.touchPoints.forEach(touchPoint => {
						if ((touchPoint.startInSec <= state.playedSeconds) && (state.playedSeconds < touchPoint.startInSec + 3)) {
							if (!touchPointsettings.show) {
								setTouchPointSettings({ show: true, time: touchPoint.timeInSec, x: touchPoint.x, y: touchPoint.y, path: touchPoint.path, name: touchPoint.name });
							}
						}
					})
				})
			}
		}
		if ((duration > 500) && (state.playedSeconds > duration - 2.5)) handleEnd();
	}

	// If the user choses to watch a new part of the video in the menu, seeks the video to the right timecode and update the newX fot the timeline to update accordingly
	const handleCloseMenu = (newStartInSeconds = undefined) => {
		if (newStartInSeconds) {
			setNewX(window.innerWidth * newStartInSeconds / duration);
			player.current.seekTo(newStartInSeconds / duration);
			handleProgress({ playedSeconds: newStartInSeconds, played: newStartInSeconds / duration, loadedSeconds: state.loadedSeconds, loaded: state.loaded });
		}
		setShowMenu(false);
	}

	const handleMenuClick = () => {
		if (!showMenu) {
			setShowMenu(true);
			setCloseMenu(false);
		}
		else {
			setCloseMenu(true);
		}
	}

	const handleEnd = () => {
		endFadeToBack(path + "-end-menu");
	}

	const handleLogoClick = () => {
		endFadeToBack("/");
	}

	//Transition to end menu or to homepage
	const endFadeToBack = (path) => {
		() => setState({ ...state, playing: false });
		document.querySelector("#videoVisitblackFadeIn").classList.remove("videofadeIn");
		document.querySelector("#videoVisitblackFadeIn").classList.add("showQuick");
		document.querySelector("#videoVisitblackFadeIn").style.zIndex = 5;
		setTimeout(() => navigate(path), 500);
	}

	return (<>
		{showMenu &&
			<Menu
				videoParts={videoParts}
				onCloseMenu={handleCloseMenu}
				closeMenu={closeMenu}
				currentVideoPartIndex={currentVideoPartIndex}
				windowRatio={windowRatio}
				path={path}
			/>
		}
		{(touchPointsettings.show && !showMenu && showVideo) &&
			<TouchPoint
				size={130}
				thickness={4}
				time={touchPointsettings.time}
				x={touchPointsettings.x}
				y={touchPointsettings.y}
				path={touchPointsettings.path}
				name={touchPointsettings.name}
				onEndTouchPoint={() => { setTouchPointSettings({ ...touchPointsettings, show: false }) }}
				windowRatio={windowRatio}
				muted={state.muted}
			/>
		}

		<VideoButtons
			showVideo={showVideo}
			showMenu={showMenu}
			onSoundClick={() => setState({ ...state, muted: !state.muted })}
			onPauseClick={() => setPause(pause => !pause)}
			onMenuClick={handleMenuClick}
			state={state}
			touchPointShow={touchPointsettings.show}
			onLogoClick={handleLogoClick}
		/>


		<section id="video-section">
			<div id="video-wrapper"
				style={windowRatio > 16 / 9 ?
					{
						width: "100vw",
						height: `${100 * 9 / 16}vw`,
					} :
					{
						height: "100vh",
						width: `${100 * 16 / 9}vh`,
					}
				}
			>
				<ReactPlayer
					ref={player}
					id="visit-video"
					url={videoPath}
					controls={false}
					height='100%'
					width='100%'
					onReady={() => { if (location.pathname == path) setState({ ...state, playing: true }) }}
					onStart={() => {
						document.querySelector("#videoVisitblackFadeIn").classList.add("videofadeIn")
						document.querySelector("#videoVisitblackFadeIn").style.zIndex = 2
						setPlayerReady(true)
					}}
					onProgress={handleProgress}
					onDuration={() => setDuration(player.current.getDuration())}
					onBuffer={() => setBuffering(true)}
					onBufferEnd={() => setBuffering(false)}
					onEnded={handleEnd}
					playing={state.playing}
					muted={state.muted}
					played={state.played}
					config={playerConfig}
					fileconfig={{ attributes: { playsinline: true } }}
					playsinline={true}
				/>
			</div>
		</section>

		<div id="videoVisitblackFadeIn" />

		{startTimeline &&
			<Timeline
				state={state}
				showVideo={showVideo}
				showMenu={showMenu}
				touchPointsettings={touchPointsettings}
				newX={newX}
				onSeek={handleSeek}
				currentVideoPartIndex={currentVideoPartIndex}
				videoParts={videoParts}
				duration={duration}
				onSetCheckPosition={(checkPosition) => setEnableTouchPoints(checkPosition)}
				buffering={buffering}
			/>
		}
	</>
	);

}
