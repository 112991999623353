import { useEffect, useState, useRef } from "react";
import "../../style.css";
import "./pageStyle.css";
//import video from "../../videoPaths.js"
import subtitleFR from "../../videoSubtitles-fr.js"
import subtitleEN from "../../videoSubtitles-en.js"
import ReactPlayer from "react-player";
import PageVideoControls from "./PageVideoControls";
import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'
import { useLanguage } from '../../Contexts/LanguageContext.jsx';

export default function PageVideo({ children, videoName, noControls, backgroundColor = "transparent", backgroundImage = "none", videoSize = "big", hasSubtitle = true, style = {} }) {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	const player = useRef();
	const playerWrapper = useRef();
	const [duration, setDuration] = useState(0);
	const [pause, setPause] = useState(true);
	const [ended, setEnded] = useState(false);
	const [buffering, setBuffering] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [state, setState] = useState({
		url: null,
		pip: false,
		playing: false,
		controls: false,
		light: false,
		volume: 0.8,
		muted: false,
		played: 0,
		loaded: 0,
		duration: 0,
		playbackRate: 1.0,
		loop: false,
		seeking: false
	});

	const [playerConfig, setPlayerConfig] = useState({
		file: {
			attributes: {
				crossOrigin: 'anonymous',
			},
		},
	});

	useEffect(() => {
		if (currentLanguage === 'fr') {
			if (
				hasSubtitle &&
				subtitleFR &&
				(subtitleFR[videoName] !== undefined && subtitleFR[videoName] !== null && subtitleFR[videoName] !== "")
			) {
				setPlayerConfig((prevConfig) => ({
					...prevConfig,
					tracks: [
						{
							kind: 'subtitles',
							src: subtitleFR[videoName],
							srcLang: currentLanguage,
							default: true,
						},
					],
				}));
			}
		} else {
			if (
				hasSubtitle &&
				subtitleEN &&
				(subtitleEN[videoName] !== undefined && subtitleEN[videoName] !== null && subtitleEN[videoName] !== "")
			) {
				setPlayerConfig((prevConfig) => ({
					...prevConfig,
					tracks: [
						{
							kind: 'subtitles',
							src: subtitleEN[videoName],
							srcLang: currentLanguage,
							default: true,
						},
					],
				}));
			}
		}
	}, [currentLanguage]);

	// if the pause button is toggled, pause the player
	useEffect(() => {
		setState({ ...state, playing: !pause });
	}, [pause]);

	// If the user seeks to another timecode on the timeline, updates the player accordingly
	const handleSeek = (seekPos) => {
		setState({ ...state, seeking: true });
		player.current.seekTo(seekPos, 'fraction');
		setState({ ...state, playedSeconds: seekPos * duration, played: seekPos, playing: true, });
		setState({ ...state, seeking: false });
	}

	//Updates the state of the player on every onProgress() call of the player
	const handleProgress = ({ playedSeconds, played, loadedSeconds, loaded }) => {
		if (!state.seeking) {
			setState({ ...state, playedSeconds: playedSeconds, played: played, loadedSeconds: loadedSeconds, loaded: loaded });
		}
	}

	// If the replay button is clicked, restart the video
	const handleReplay = () => {
		handleSeek(0);
		setState({ ...state, playing: true });
		setEnded(false);
	}

	return (
		<div id="page-background" className={`video ${backgroundImage !== "none" ? "gradient" : backgroundColor === "transparent" ? "transparent" : "beige"}`} style={style}>
			<div className="page-block" style={isReady ? { opacity: "100%" } : { opacity: "0%", transition: "opacity 0.5s 1s" }}>
				{children && (
					<h1 className="paddingTopBottom">{children}</h1>
				)}

				<div className={videoSize} id="page-videos-wrapper" ref={playerWrapper} >
					<div id="page-video" >
						<ReactPlayer
							ref={player}
							url={video[videoName]}
							controls={false}
							height='100%'
							width='100%'
							loop={noControls ? true : false}
							onReady={() => { setIsReady(true) }}
							onProgress={handleProgress}
							onDuration={() => setDuration(player.current.getDuration())}
							onBuffer={() => setBuffering(true)}
							onBufferEnd={() => setBuffering(false)}
							onEnded={() => {
								setState({ ...state, playing: false })
								setEnded(true)
							}}
							playing={noControls ? true : state.playing}
							muted={noControls ? true : state.muted}
							played={state.played}
							config={playerConfig}
							fileconfig={{ attributes: { playsinline: true } }}
							playsinline={true}
						/>
					</div>
					{!noControls && isReady &&
						<PageVideoControls
							state={state}
							onSeek={handleSeek}
							duration={duration}
							onSoundClick={() => setState({ ...state, muted: !state.muted })}
							onPauseClick={() => setPause(pause => !pause)}
							onReplayClick={handleReplay}
							buffering={buffering}
							ended={ended}
							onFullscreenClick={() => screenfull.request(findDOMNode(playerWrapper.current))}
						/>
					}
				</div>
			</div>
		</div >
	)
}
