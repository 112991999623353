import { useState, useEffect, useMemo } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import HomePage from "./Pages/HomePage.jsx";
import PageTemplate from './Components/Pages/PageTemplate.jsx';
import PageNotFound from './Pages/PageNotFound.jsx';
import EndLoop from "./Pages/EndLoop.jsx";
import Laboratoires from "./Pages/Laboratoires.jsx";
import Usine from "./Pages/Usine.jsx";
import "./style.css";
import { userManager } from './oidcConfig.js'; // Import the OIDC configuration
import { useLanguage } from './Contexts/LanguageContext.jsx';

export default function Front() {
	const navigate = useNavigate();
	const { currentLanguage } = useLanguage();

	const videoPartsLaboRef = currentLanguage === 'fr'
		? import('./videoParts-labo-fr.js')
		: import('./videoParts-labo-en.js');

	const videoPartsUsineRef = currentLanguage === 'fr'
		? import('./videoParts-usine-fr.js')
		: import('./videoParts-usine-en.js');

	const [videoPartsLabo, setVideoPartsLabo] = useState([]);
	const [videoPartsUsine, setVideoPartsUsine] = useState([]);

	videoPartsLaboRef.then((videoPartsLaboEl) => {
		setVideoPartsLabo(videoPartsLaboEl.default);
	});
	videoPartsUsineRef.then((videoPartsUsineEl) => {
		setVideoPartsUsine(videoPartsUsineEl.default);
	});

	const [windowRatio, setWindowRatio] = useState(window.innerWidth / window.innerHeight);
	const [soundOn, setSoundOn] = useState(false);
	const [visit, setVisit] = useState("");
	const [audio] = useState(new Audio("sounds/intro-music.mp3"));

	useEffect(() => {
		const handleWindowResize = () => setWindowRatio(window.innerWidth / window.innerHeight);
		window.addEventListener("resize", handleWindowResize);
		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	// Check for the pathname to set up the correct visit
	useEffect(() => {
		if (location.pathname.slice(0, 6) === "/usine") setVisit("/usine");
		else if (location.pathname.slice(0, 13) === "/laboratoires") setVisit("/laboratoires");
	}, [location.pathname])

	const [pages, setPages] = useState([]);

	useEffect(() => {
		const localPages = [];

		[videoPartsLabo, videoPartsUsine].forEach(videoParts => {
			videoParts.forEach((videoPart => {
				videoPart.touchPoints.forEach(touchPoint => {
					if (!touchPoint.anchorPath) {
						localPages.push({
							path: touchPoint.path,
							name: touchPoint.name,
							chapter: videoPart.name,
							subSections: touchPoint.subSections,
							file: touchPoint.file,
							visitPath: videoParts == videoPartsLabo ? "/laboratoires" : "/usine"
						})
						if (touchPoint.subSections) touchPoint.subSections.forEach(subSection => localPages.push({
							path: subSection.path,
							parentPath: touchPoint.path,
							name: subSection.name,
							chapter: videoPart.name,
							subSections: subSection.redirection,
							file: subSection.file,
							visitPath: videoParts == videoPartsLabo ? "/laboratoires" : "/usine"
						}))
					}
				})
			}));
		})

		setPages(localPages);
	}, [videoPartsLabo, videoPartsUsine])

	//
	const [isPortrait, setIsPortrait] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [showOrientationMsg, setShowOrientationMsg] = useState(false);

	const userAgent = navigator.userAgent;

	const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
	const isMobileDevice = mobileRegex.test(userAgent);

	const displayOrientation = () => {
		const screenOrientation = screen.orientation.type;
		if (screenOrientation === 'portrait-primary') {
			setIsPortrait(true);
		} else {
			setIsPortrait(false);
		}
	};

	useEffect(() => {
		if (isMobileDevice) {
			setIsMobile(true);

			if (screen && screen.orientation !== null && screen.orientation.type == 'portrait-primary') {
				setIsPortrait(true);
				setShowOrientationMsg(true);
			}

			if (screen && screen.orientation !== null) {
				try {
					window.screen.orientation.onchange = displayOrientation;
				}
				catch (e) { }
			}
		}
	}, [isMobileDevice]);

	useEffect(() => {
		if (isPortrait && showOrientationMsg) {
			let openingEl = document.getElementById("opening");
			let introEl = document.getElementById("into-background");

			if (openingEl && introEl) {
				openingEl.style.display = 'none';
				introEl.style.display = 'none';
			}
		}
	}, [pages]);

	useEffect(() => {
		if (!isPortrait) {
			let openingEl = document.getElementById("opening");
			let introEl = document.getElementById("into-background");

			if (openingEl && introEl) {
				openingEl.style.display = 'flex';
				introEl.style.display = 'flex';
			}
		}
	}, [isPortrait]);

	return <>
		{showOrientationMsg && isMobile && isPortrait &&
			<div
				onClick={() => {
					setShowOrientationMsg(false);

					let openingEl = document.getElementById("opening");
					let introEl = document.getElementById("into-background");

					if (openingEl && introEl) {
						openingEl.style.display = 'flex';
						introEl.style.display = 'flex';
					}
				}}
				style={{
					position: 'fixed',
					zIndex: 9999,
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					background: 'rgb(0 0 0 / 65%)',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					color: '#FFF'
				}}>
				<div style={{ marginBottom: '30px' }}>
					<img src="/images/general/rotation.png" width="200px" />
				</div>

				{currentLanguage === 'fr' ? (
					<div style={{ color: '#EEE', textTransform: 'uppercase', lineHeight: '1.75em', textAlign: 'center' }}>
						Tournez votre appareil <br />puis rafraîchissez la page<br />pour une expérience optimale.
					</div>
				) : (
					<div style={{ color: '#EEE', textTransform: 'uppercase', lineHeight: '1.75em', textAlign: 'center' }}>
						Turn your device <br />then refresh the page <br />for the best experience.
					</div>
				)}

				<span style={{ marginTop: '60px', fontSize: '11px', textTransform: 'uppercase', color: '#CCC' }}>Continuer en mode portrait</span>
			</div >
		}

		{visit === "/laboratoires" ?
			<Laboratoires
				windowRatio={windowRatio}
				soundOn={soundOn}
				onSoundClick={(soundOn) => setSoundOn(soundOn)}
			/> :
			<Usine
				windowRatio={windowRatio}
				soundOn={soundOn}
				onSoundClick={(soundOn) => setSoundOn(soundOn)}
			/>
		}

		<Routes>
			<Route path="*" element={<PageNotFound windowRatio={windowRatio} audio={audio} soundOn={soundOn} />} />
			<Route path="/" element={<HomePage onPrepareVisit={(path) => setVisit(path)} audio={audio} onEntered={() => setSoundOn(true)} windowRatio={windowRatio} />} />
			<Route path="/laboratoires" element={<div />} />
			<Route path="/laboratoires-end-menu" element={<EndLoop onChangeVisit={(path) => setVisit(path)} soundOn={soundOn} onSoundClick={() => setSoundOn(soundOn => !soundOn)} audio={audio} redirection={"/usine"} onEntered={() => setSoundOn(true)} windowRatio={windowRatio} />} />
			<Route path="/usine" element={<div />} />
			<Route path="/usine-end-menu" element={<EndLoop onChangeVisit={(path) => setVisit(path)} soundOn={soundOn} onSoundClick={() => setSoundOn(soundOn => !soundOn)} audio={audio} redirection={"/laboratoires"} onEntered={() => setSoundOn(true)} windowRatio={windowRatio} />} />

			{pages.length > 0 && pages.map((value, index) =>
				<Route
					key={index}
					path={value.path}
					element={
						<PageTemplate
							name={value.name}
							parentPath={value.parentPath}
							chapter={value.chapter}
							subSections={value.subSections}
							file={value.file}
							windowRatio={windowRatio}
							visitPath={value.visitPath}
						/>
					}
				/>
			)}
		</Routes>
	</>
}